<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>
              Pending PPE Requests
            </h1>
          </div>
          <div class="pa-5 d-flex">
            <v-row no-gutters>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="6 d-flex mt-auto mb-auto">
                    <v-select v-model="action" class="mr-5 action-select" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0" label="Actions" return-object outlined dense />
                  </v-col>
                  <v-col cols="6 d-flex mt-auto mb-auto">
                    <div class="submit-button-container">
                      <Button :label="'Submit'"
                              :btnType="'Submit'"
                              @onClick="approveBulkSelection"
                              :isLoading="loading"
                              class="" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <!-- <div v-if="loadingPPE" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div> -->
          <v-data-table
            :headers='headers'
            :loading ="loadingData"
            :items="pendingPpeType"
            show-select
            :single-select="false"
            v-model="selected"
            item-key="requestGuid"
          >
            <template v-slot:item.statusDate="{ item }">
              {{item.statusDate | formatDate}}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <SmallButton :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="view(item)" class="mr-2 small-button" />
                <SmallButton :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfirmDialog(item)" class="mr-2" />
                <SmallButton :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" class="mr-2" />
                <SmallButton :btnType="'Forward'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2" />
                <!--<SmallButton :btnType="'Submit'" :label="'Return'" @onClick="(item) =>{}" /> -->
              </div>
            </template>
          </v-data-table>
          <v-dialog v-model="ppeViewModal" :title="'PPE'" :width="600">
            <v-card v-if="ppeRequest !== null">
              <v-card-title class="headline text-center"><h4>{{ppeRequest.employeeName}}</h4></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-simple-table style="width: 100%">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>PPE Type</th>
                      <th>Quantity</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ppeItems" :key="item.requestId">
                      <td><p>{{item.ppeType.name}}</p></td>
                      <td><p>{{item.narration}}</p></td>
                      <td>
                        <p v-if="item.request.status == 0">Pending </p>
                        <p v-if="item.request.status == 1">Approved </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- Logs-->
              <v-row>
                <v-card width="600" elevation="0">
                  <v-tabs v-model="tab">
                    <v-tab href="#tab-2">Log</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-2">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular indeterminate
                                               color="primary"></v-progress-circular>
                        </div>
                        <v-data-table v-else
                                      :headers='logHeaders'
                                      :items="logs">
                          <template v-slot:item.activityDate="{ item }">
                            <span>{{ item.activityDate | formatDate }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-row>
              <!--End Logs-->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeView">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField v-model="formData.comments" :label="'Comment'" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Reject'"
                        :btnType="'Submit'"
                        @onClick="reject"
                        :disabled="!valid"
                        class="mr-4" />
                <v-btn color="green darken-1" text @click="closeCancelModal">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
     <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)"  @close="personnelSearch = false" @commentPer="getComment" :mType="'forward'" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
    <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="approvalLoading"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/SmallButton.vue'
import SmallButton from '@/components/ui/form/Button.vue'
import { requestService } from '@/services'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import {
  APPROVE_BULK_PPE,
  FETCH_PENDING_PPES,
  FORWARD_REQUEST
} from '@/store/action-type'

export default {
  components: {
    TextField,
    Button,
    SmallButton,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      loading: false,
      loadingData: false,
      loadingLogs: false,
      alertType: '',
      approvalLoading: false,
      dialog: false,
      item: null,
      ppeViewModal: false,
      action: '',
      rejectDialog: false,
      valid: false,
      bulk: false,
      selected: [],
      ppeRequest: null,
      ppeItems: [],
      personnelSearch: false,      
      DelegateName: null,
      DelegateEmail: null,
      comment: '',
      forwarding: false,
      formData: {
        comments: ''
      },
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      headers: [
        {
          text: 'Personnel Name',
          value: 'employeeName'
        },
        {
          text: 'No. of Request',
          value: 'requestCount'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Job Title',
          value: 'jobTitle'
        },
        {
          text: 'Employer',
          value: 'contractEmployerName'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Date Added',
          value: 'requestDate'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      logs: [],
      tab: null,
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comment',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }

      ]
    }
  },
  computed: {
    pendingPpeType () {
      return this.$store.getters.pendingPpeType
    }
  },

  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    openConfirmDialog (item) {
      this.dialog = true
      this.item = item
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    approve () {
      this.approvalLoading = true
      const approvals = []
      approvals.push({
        status: 1,
        guid: this.item.requestGuid
      })

      this.$store
        .dispatch(APPROVE_BULK_PPE, approvals)
        .then((result) => {
          this.showAlertMessage('PPE request successfully approved', 'success')
          // this.$router.go()
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approval request failed', 'error')
          // this.$router.go()
        }).finally(() => {
          this.item = null
          this.dialog = false
          this.approvalLoading = false
        })

      this.getRequests()
    },
    approveBulkSelection () {
      if (this.action.id === 2 && this.formData.comments === '') {
        this.rejectDialog = true
        this.bulk = true
        return
      }
      let bulkItems = []

      this.selected.forEach(element => {
        bulkItems.push({ guid: element.requestGuid, Status: this.action.id, Comments: this.action.id === 1 ? '' : this.formData.comments })
      })

      this.rejectDialog = false
      this.loading = true
      this.$store.dispatch(APPROVE_BULK_PPE, bulkItems).then((result) => {
        this.showAlertMessage(`${this.action.name} Requests successful`, 'success')
        // eslint-disable-next-line handle-callback-err
      })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage(`${this.action.name}  Requests failed`, 'error')
        }).finally(() => {
          this.loading = false
          bulkItems = []
          this.formData.comments = ''
          this.selected = []
        })
    },
    openCancelModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    closeCancelModal () {
      this.rejectDialog = false
      this.item = null
    },
    reject () {
      if (this.bulk === true) {
        this.approveBulkSelection()
        return
      }
      const approvals = []
      // this.item.status = 2
      // this.item.comment = this.formData.comments
      approvals.push({
        status: 2,
        guid: this.item.requestGuid,
        comments: this.formData.comments
      })

      this.$store
        .dispatch(APPROVE_BULK_PPE, approvals)
        .then((result) => {
          this.showAlertMessage('PPE request successfully rejected', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('PPE Request failed', 'error')
        }).finally(() => {
          this.rejectDialog = false
          this.formData.comments = ''
        })

      this.getRequests()
    },

    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name      
    },
    getComment (comment) {
      this.comment = comment
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        ItemGuid: this.item.requestGuid,
        // "ItemId":428,//This should be the Employee Id        
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail,
        'comment': this.comment
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'PPE' }).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    },

    view (item) {
      this.ppeItems = item.ppEs
      this.ppeRequest = item
      this.ppeViewModal = true
      // this.$refs.ppeViewModal.openModal()
      console.log('Items', item)
      this.loadingLogs = true
      requestService.ppeApprovalLogs(item.requestGuid).then(({ data }) => {
        console.log('This is Data', data)

        this.logs = data.logs === null ? [] : data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
    },

    closeView () {
      this.ppeViewModal = false
    },
    getRequests () {
      this.loadingData = true
      this.$store
        .dispatch(FETCH_PENDING_PPES)
        .then(() => { this.loadingData = false })
        .catch((error) => {
          console.log(error)
          if (error) {
            this.showAlertMessage('There are no items pending your approval', 'warning')
            this.loadingData = false
          }
        })
    }
  },
  mounted () {
    this.getRequests()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.action-select {
 padding-top: 24px;
}
.small-button {
    font-size: 11px !important;
    height: 25px !important;
}
</style>
